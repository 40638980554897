import React, { useEffect } from "react";
import RootLayout from "../../component/layout";
import { useNavigate } from "react-router-dom";
import PaymentSuccess from "../../component/payment/PaymentSuccess";

const SuccessPayment = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get("paymentId");
  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentId) {
      navigate("/error/config");
    }
  }, []);

  return (
    <RootLayout>
      <PaymentSuccess
        imageSource="/success.png"
        title="Payment Successful!"
        description="Your transaction has been completed successfully"
        buttonText="Back to Home"
        backText="View Receipt"
      />
    </RootLayout>
  );
};

export default SuccessPayment;
