import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Button from "../common/Button";
import { submitXenithKyc } from "../../redux/actions/xenithActions";
import imageCompression from "browser-image-compression";
import { ALLOWED_COUNTRY_CODES } from "../../utils/utils";

const userDetails = {
  passport: null,
  selfie: null,
  address: "",
  city: "",
  pin: "",
  dob: "",
  country: "",
};

const XenithKycForm = () => {
  const [user, setUser] = useState(userDetails);
  const [passportPreview, setPassportPreview] = useState(null);
  const [selfiePreview, setSelfiePreview] = useState(null);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const [loadingverify, setLoadingVerify] = useState(false);
  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const location = useLocation();
  const [status, setStatus] = useState(location.state?.status || "");
  const [kycRejectLabel, setKycRejectLabel] = useState(
    location.state?.kycRejectLabel || ""
  );
  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );
  const [isPassportLoading, setIsPassportLoading] = useState(false);
  const [isSelfieLoading, setIsSelfieLoading] = useState(false);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5, // 500KB = 0.5MB
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      showErrorMessage("Error compressing image. Please try again.");
      return null;
    }
  };

  const handleImageUpload = async (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      // Set loading state based on image type
      if (type === "passport") {
        setIsPassportLoading(true);
      } else {
        setIsSelfieLoading(true);
      }

      // Validate file size before compression
      const maxSizeInMB = 5; // 5MB max before compression
      if (file.size > maxSizeInMB * 1024 * 1024) {
        throw new Error(`File size should not exceed ${maxSizeInMB}MB`);
      }

      let processedFile = file;

      // Check if file is HEIC/HEIF format
      if (file.type === "image/heic" || file.type === "image/heif") {
        try {
          const heic2any = (await import("heic2any")).default;
          const jpgBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8,
          });
          processedFile = new File(
            [jpgBlob],
            file.name.replace(/\.(heic|heif)$/i, ".jpg"),
            {
              type: "image/jpeg",
            }
          );
        } catch (heicError) {
          throw new Error(
            "Failed to convert HEIC/HEIF image. Please upload a JPEG or PNG file."
          );
        }
      }

      // Compress the image
      const compressedFile = await compressImage(processedFile);
      if (!compressedFile) {
        throw new Error("Failed to compress image. Please try again.");
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        setUser((prev) => ({
          ...prev,
          [type]: base64Data,
        }));
        if (type === "passport") {
          setPassportPreview(reader.result);
        } else if (type === "selfie") {
          setSelfiePreview(reader.result);
        }
      };
      reader.onerror = () => {
        throw new Error("Failed to read the image file. Please try again.");
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error handling image upload:", error);
      showErrorMessage(
        error.message || "Error uploading image. Please try again."
      );
    } finally {
      setIsPassportLoading(false);
      setIsSelfieLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setUser((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!validateFields()) {
        return;
      }

      setLoadingVerify(true);

      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (status === "SOFT_KYC_FAILED" || status === "HARD_KYC_FAILED") {
        toast.error("Please contact admin support for assistance.");
      } else {
        const response = await dispatch(
          submitXenithKyc(
            {
              passport: user.passport,
              selfie: user.selfie,
              address: user.address,
              city: user.city,
              pin: user.pin,
              dob: formatDate(user.dob),
              country: user.country,
            },
            keyUsed,
            checkoutConfig?._id
          )
        );
        if (response.code === 1) {
          navigate("/xenith/kyc/awaiting", {
            state: {
              keyUsed: keyUsed,
              id: checkoutConfig?._id,
            },
          });
        } else {
          showErrorMessage(
            response?.data || "Failed to submit KYC. Please try again."
          );
        }
      }
    } catch (error) {
      console.error("Error submitting KYC:", error);
      showErrorMessage(
        error.message || "Failed to submit KYC. Please try again."
      );
    } finally {
      setLoadingVerify(false);
      setStatus("");
      setKycRejectLabel("");
    }
  };

  const validateCountryCode = (country) => {
    return ALLOWED_COUNTRY_CODES.includes(country.toUpperCase());
  };

  const validateCountry = () => {
    const errors = {};

    // Enhanced country code validation
    if (!user.country.trim()) {
      errors.country = "Please enter your country code";
    } else {
      const countryCode = user.country.trim().toUpperCase();
      if (!validateCountryCode(countryCode)) {
        errors.country = `Invalid country code. Please enter a valid 2-letter country code (e.g., US, UK, IN)`;
      }
    }

    // Clear country error if valid, otherwise set the error
    if (Object.keys(errors).length > 0) {
      setValidationErrors((prev) => ({ ...prev, country: errors.country }));
      return false;
    } else {
      setValidationErrors((prev) => ({ ...prev, country: "" }));
      return true;
    }
  };

  const validateFields = () => {
    const errors = {};

    // Existing validations
    if (!user.passport) {
      errors.passport = "Please upload your passport";
    }
    if (!user.selfie) {
      errors.selfie = "Please upload your selfie";
    }
    if (!user.address.trim()) {
      errors.address = "Please enter your address";
    }
    if (!user.city.trim()) {
      errors.city = "Please enter your city";
    }
    if (!user.dob) {
      errors.dob = "Please select your date of birth";
    }
    
    // Add country validation
    if (!validateCountry()) {
      toast.error("Please enter a valid country code");
      return false;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const showErrorMessage = (errMessage) =>
    toast.error(errMessage, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <div className="w-full flex flex-col items-center justify-start pb-[100px]">
      <div className="w-full bnksy-input-group mb-[20px]">
        <label className="block mb-2 text-sm font-medium">Address</label>
        <input
          type="text"
          className="bnksy-form-input"
          placeholder="Address"
          value={user.address}
          onChange={(e) => handleInputChange("address", e.target.value)}
          required
        />
        {validationErrors.address && (
          <p className="text-red-600 text-sm m-1">{validationErrors.address}</p>
        )}
      </div>

      <div className="flex justify-between w-full">
        <div className="w-full bnksy-input-group mb-[20px] mr-5">
          <label className="block mb-2 text-sm font-medium">City</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="City"
            value={user.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
            required
          />
          {validationErrors.city && (
            <p className="text-red-600 text-sm m-1">{validationErrors.city}</p>
          )}
        </div>
        <div className="w-full bnksy-input-group mb-[20px]">
          <label className="block mb-2 text-sm font-medium">Country</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="Country code (e.g., US, UK, IN)"
            value={user.country}
            maxLength={2}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              handleInputChange("country", value);
            }}
            onBlur={() => validateCountry()}
            disabled={loadingverify}
            style={{ textTransform: 'uppercase' }}
            required
          />
                 
          {validationErrors.country && (
            <p className="text-red-600 text-sm m-1">
              {validationErrors.country}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-between w-full">
        <div className="w-full bnksy-input-group mb-[20px] mr-5">
          <label className="block mb-2 text-sm font-medium">
            Date of Birth
          </label>
          <div className="relative">
            <DatePicker
              selected={user.dob ? new Date(user.dob) : null}
              onChange={(date) => handleInputChange("dob", date)}
              dateFormat="yyyy-MM-dd"
              className="bnksy-form-input"
              placeholderText="Date Of Birth"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              maxDate={new Date()}
              required
            />
            <div className="absolute calendar-icon-box right-[16px] top-1/2 -translate-y-1/2 z-0">
              <img
                src="/calendar-icon.svg"
                alt="logo"
                className="w-full logo-transparent-white"
              />
            </div>
          </div>
          {validationErrors.dob && (
            <p className="text-red-600 text-sm m-1">{validationErrors.dob}</p>
          )}
        </div>

        <div className="w-full bnksy-input-group mb-[20px]">
          <label className="block mb-2 text-sm font-medium">Postal Code</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="Postal code"
            value={user.pin}
            onChange={(e) => handleInputChange("pin", e.target.value)}
            required
          />
          {validationErrors.pin && (
            <p className="text-red-600 text-sm m-1">{validationErrors.pin}</p>
          )}
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gender-title relative mb-[20px]">
          <span className="common-lbl text-center w-full">
            Select Documents
          </span>
        </div>
        <div className="flex gap-6">
          <div className="w-1/2 text-center h-full">
            <div className="flex items-center justify-center common-lbl mb-[10px]">
              <span>Passport / Drivers License Photo</span>
            </div>
            <div
              className={`relative overflow-hidden w-full flex flex-col items-center ${
                passportPreview ? "justify-center" : "justify-center"
              } upload-box mx-auto`}
            >
              <div
                className={`flex items-center ${
                  passportPreview ? "justify-between" : "justify-center"
                } w-full h-full`}
              >
                {isPassportLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="passport-upload"
                      onChange={(e) => handleImageUpload(e, "passport")}
                      required
                    />
                    {!passportPreview && (
                      <label
                        htmlFor="passport-upload"
                        className="flex items-center justify-center cursor-pointer w-full h-full"
                      >
                        <img
                          src="/upload.svg"
                          alt="icon"
                          className="logo-transparent-white"
                        />
                        <div className="flex flex-col text-left ml-3">
                          <span className="common-lbl">Upload file</span>
                          <span className="common-lbl font-small upload-small-lbl">
                            Format: JPG, PNG (Max: 500KB)
                          </span>
                        </div>
                      </label>
                    )}
                    {passportPreview && (
                      <>
                        <label
                          htmlFor="passport-upload"
                          className="Upload-EditIcon bg-primary text-white cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-camera-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                          </svg>
                        </label>
                        <img
                          src={passportPreview}
                          alt="Passport Preview"
                          className="max-w-full w-full h-full object-contain"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {validationErrors.passport && (
              <p className="text-left text-red-600 font-small">
                {validationErrors.passport}
              </p>
            )}
          </div>

          <div className="w-1/2 text-center flex flex-col justify-between">
            <div className="flex items-center justify-center common-lbl mb-[10px]">
              <span>Selfie</span>
            </div>
            <div
              className={`relative overflow-hidden w-full flex flex-col items-center ${
                selfiePreview ? "justify-center" : "justify-center"
              } upload-box mx-auto`}
            >
              <div
                className={`flex items-center ${
                  selfiePreview ? "justify-between" : "justify-center"
                } w-full h-full`}
              >
                {isSelfieLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="selfie-upload"
                      onChange={(e) => handleImageUpload(e, "selfie")}
                      required
                    />
                    {!selfiePreview && (
                      <label
                        htmlFor="selfie-upload"
                        className="flex items-center justify-center cursor-pointer w-full h-full"
                      >
                        <img
                          src="/upload.svg"
                          alt="icon"
                          className="logo-transparent-white"
                        />
                        <div className="flex flex-col text-left ml-3">
                          <span className="common-lbl">Upload file</span>
                          <span className="common-lbl font-small upload-small-lbl">
                            Format: JPG, PNG (Max: 500KB)
                          </span>
                        </div>
                      </label>
                    )}
                    {selfiePreview && (
                      <>
                        <label
                          htmlFor="selfie-upload"
                          className="Upload-EditIcon bg-primary text-white cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-camera-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                          </svg>
                        </label>
                        <img
                          src={selfiePreview}
                          alt="Selfie Preview"
                          className="max-w-full w-full h-full object-contain"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {validationErrors.selfie && (
              <p className="text-left text-red-600 font-small">
                {validationErrors.selfie}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
        <Button
          onClick={handleSubmit}
          loading={loadingverify}
          disabled={
            !user.passport ||
            !user.selfie ||
            !user.address ||
            !user.city ||
            !user.country ||
            !user.pin ||
            !user.dob 
          }
        >
          Submit
        </Button>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default XenithKycForm;
