import jwt from "jsonwebtoken";

const publicKey = process.env.REACT_APP_JWT_KEY || "";

// Function to convert base64 to ArrayBuffer

export const verifyToken = (token) => {
  try {
    const pubkey = atob(publicKey);
    const payload = jwt.verify(token, pubkey, { algorithms: ["RS256"] });
    return payload;
  } catch (error) {
    console.error("Error verifying token:", error);
    throw error;
  }
};

export const faqContent = (
  <div className="p-6 space-y-4 text-gray-300">
    <p>
      1) Please send <strong>0.00015000 BTC</strong> to the address{" "}
      <code className="text-pink-500">3EqXpuBvYmjVHY8fqmpYzWdrkszWMgCfQn</code>.
      Ensure the amount covers transaction fees! Initiate the payment using your
      wallet software, and copy/paste the address and payment amount. We will
      email you when all funds are received. You have{" "}
      <strong>3 hours, 29 minutes, 59 seconds</strong> to complete this. If
      funds aren’t confirmed by then, you’ll get an automatic email to claim
      within 8 hours. If not received, contact us with:
    </p>
    <ul className="list-disc list-inside ml-4">
      <li>
        Transaction ID:{" "}
        <code className="text-pink-500">CPIJ78SXVYLNTSFIO9WFDLJRTO</code>
      </li>
      <li>Your return payment address.</li>
      <li>
        Verification code:{" "}
        <code className="text-pink-500">f4ac7cf931da90f9c91bf0c382aca48a</code>
      </li>
    </ul>

    <p className="mt-4">
      2) After sending, review your transaction status on this page. Once
      confirmed in the blockchain, the payment will be complete, and the
      merchant will be notified. Confirmation can take 10-45 minutes but varies
      with block time and required confirmations. The status page is available
      for the next 30 days.
    </p>

    <h4 className="mt-6 font-semibold text-white">
      What if I accidentally don't send enough?
    </h4>
    <p>
      If you don't send enough, simply send the remaining amount. We will
      combine the transactions. You can also send from multiple
      wallets/accounts.
    </p>
  </div>
);

export const ALLOWED_COUNTRY_CODES = [
  "AD", "AE", "AG", "AI", "AL", "AM", "AO", "AR", "AS", "AT", "AU", "AW", "AX",
  "BA", "BD", "BE", "BG", "BH", "BJ", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BZ",
  "CA", "CC", "CG", "CH", "CI", "CK", "CL", "CM", "CO", "CR", "CV", "CW", "CX", "CY", "CZ",
  "DE", "DJ", "DK", "DM", "DO", "DZ",
  "EC", "EE", "EG", "ER", "ES", "ET",
  "FI", "FJ", "FK", "FM", "FO", "FR", "GF",
  "GA", "GB", "GD", "GE", "GG", "GI", "GL", "GM", "GP", "GQ", "GR", "GT", "GU", "GY",
  "HK", "HN", "HR", "HU",
  "ID", "IE", "IL", "IM", "IN", "IO", "IS", "IT",
  "JE",
  "KE", "KI", "XK", "KM", "KN", "KR", "KW", "KY",
  "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV",
  "MC", "ME", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MR", "MQ", "MS", "MT", "MU", "MV", "MW", "YT", "MX", "MY",
  "NA", "NC", "NE", "NF", "NG", "NL", "NO", "NP", "NR", "NU", "NZ",
  "OM",
  "PE", "PF", "PG", "PK", "PL", "PN", "PT", "PR", "PW", "PY",
  "QA",
  "RE", "RO", "RS", "RW",
  "SA", "SB", "SC", "SE", "SG", "BL", "SH", "MF", "PM", "SJ", "SI", "SK", "SL", "SM", "SN", "SR", "ST", "SV", "SX", "SZ",
  "TC", "TD", "TG", "TH", "TK", "TL", "TO", "TT", "TV", "TW",
  "UY",
  "VA", "VC", "VE", "VG", "VI",
  "WF", "WS",
  "ZA", "EH", "ZM"
];
