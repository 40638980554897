import { callGetApi, callPatchApi, callPostApi } from "./axios"

export const checkStatus = async (key) => {
    const endpoint = "/common/status";
    const { data } = await callGetApi(key, endpoint);
    return data;
};

export const getModulePublicKey = async (key, moduleName) => {
    const endpoint = "/common/getKey";
    const { data } = await callGetApi(key, endpoint, { module: moduleName });
    return data;
};

export const verifyPayment = async (key, paymentId) => {
    const endpoint = "/payment/verify/" + paymentId;
    const { data } = await callPatchApi(key, endpoint, {});
    return data;
};

export const getPayment = async (key, paymentId) => {
    const endpoint = "/payment/" + paymentId;
    const { data } = await callGetApi(key, endpoint);
    return data;
};

export const verifyWyrapayPayment = async (key, transaction_uuid) => {
    const endpoint = "/wyrapay/payment-verify";
    const { data } = await callGetApi(key, endpoint, { transaction_uuid });
    return data;
};

export const verifyTripleaPayment = async (key, payment_reference) => {
    const endpoint = "/triple-a/payment-verify";
    const { data } = await callGetApi(key, endpoint, { payment_reference });
    return data;
};

export const processCheckout = async (key, payload) => {
    const endpoint = "/sumup/process-checkout";
    const { data } = await callPostApi(key, endpoint, payload);
    return data;
};

export const getSumupPayment = async (key, checkoutId) => {
    const endpoint = "/payment/checkout/" + checkoutId;
    const { data } = await callGetApi(key, endpoint);
    return data;
};


export const createPayment = async (key, payload) => {
    const endpoint = "/payment/create";
    const { data } = await callPostApi(key, endpoint, payload);
    return data;
};

export const getProviders = async (key) => {
    const endpoint = "/common/providers";
    const { data } = await callGetApi(key, endpoint);
    return data;
};

export const getCurrencies = async (key) => {
    const endpoint = "/config/currency";
    const { data } = await callGetApi(key, endpoint);
    return data;
};