import * as ActionTypes from "../actionTypes"; // Import your action types
import { apiCall } from "../api";

export const fetchXenithKycStatus = (apikey,paymentId) => async (dispatch) => {
  try {
    const response = await apiCall(
      `/sdk/xenith/get-kyc/${paymentId}`,
      "GET",
      null,apikey
    );

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};

export const reinitializeKyc = (data, key, paymentId) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/xenith/reinitialize-kyc/${paymentId}`, "POST", data, key);
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const submitXenithKyc = (data,key,paymentId) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/xenith/create-kyc/${paymentId}`, "POST", data,key);
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const submitXenithUpdatedKyc = (data,key,paymentId) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/xenith/update-kyc/${paymentId}`, "PUT", data,key);
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}


export const startXenithKyc = (key,paymentId) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/xenith/start-kyc/${paymentId}`, "POST" , {}, key);
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const fetchXenithBankDetails = (apikey,paymentId) => async (dispatch) => {
  try {
    const response = await apiCall(
      `/sdk/xenith/get-bank/${paymentId}`,
      "GET",
      null,apikey
    );

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};