import React, { useEffect } from "react";
import RootLayout from "../../component/layout";
import CreatePaymentForm from "../../component/payment/createPaymentForm.jsx";
import { useNavigate } from "react-router-dom";

const CreatePayment = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const apiKey = urlParams.get("key");
  const navigate = useNavigate();

  const isValidApiKey = (key) => {
    // Check if key starts with ck_live_ or ck_test_ followed by UUID format
    const apiKeyPattern = /^ck_(live|test)_[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
    return apiKeyPattern.test(key);
  };

  useEffect(() => {
    if (!apiKey || !isValidApiKey(apiKey)) {
      navigate("/error/config");
    }
  }, []);

  return (
    <RootLayout>
      <CreatePaymentForm apiKey={apiKey} />
    </RootLayout>
  );
};

export default CreatePayment;
