import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button";
import { toast, ToastContainer } from "react-toastify";
import { reinitializeKyc } from "../../redux/actions/xenithActions";
import { useNavigate } from "react-router-dom";

const RetryPayment = ({ paymentId, keyUsed, err }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      if (!validateFields()) {
        return;
      }
      const resenddata = {
        customerName: name,
        customerEmail: email,
      };
      setLoading(true);
      const response = await dispatch(
        reinitializeKyc(resenddata, keyUsed, paymentId)
      );
      if (response.code === 1) {
          navigate(`/?key=${response.data.keyUsed}&paymentId=${response.data._id}`);
      } else {
        toast.error(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching customer id:", error);
      setLoading(false);
    }
  };

  const validateFields = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please enter your name";
    }

    if (!email) {
      errors.email = "Please enter your email";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Fragment>
      <div>
        <div className="mb-[5rem] mt-[3rem]">
          <div className="w-full bg-rose-50 border border-rose-200 p-4 rounded-lg">
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0 mt-0.5">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="10" fill="#DC2626" />
                  <path
                    d="M12 7v6m0 2v2"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-rose-800 font-semibold mb-1">Error</h3>
                <p className="text-rose-600 text-sm">
                  {err}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center pb-[90px] md:pb-[75px] signin-page-extra-pb">
          <a href="#" className="logo-link">
            <img
              src="/banksy-black-logo.svg"
              alt="logo"
              className="w-full logo-transparent-white"
            />
          </a>
          <p className="bnksy-page-title mt-8 mb-[42px]">Reinitialize KYC</p>

          <div className="w-full max-w-3xl mx-auto px-4 md:px-4 ">
            <div className="w-full flex gap-4 mb-8">
              <div className="flex-grow">
                <label
                  htmlFor="amount-input"
                  className="block text-sm text-gray-400 mb-2"
                >
                  Name
                </label>
                <input
                  id="amount-input"
                  type="text"
                  className="w-full bg-[#1c1c1c] text-white rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-purple-600 transition-all"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {validationErrors.name && (
                  <p className="text-red-600 text-sm m-1">
                    {validationErrors.name}
                  </p>
                )}
              </div>

              <div className="flex-grow">
                <label
                  htmlFor="amount-input"
                  className="block text-sm text-gray-400 mb-2"
                >
                  Email
                </label>
                <input
                  id="amount-input"
                  type="email"
                  className="w-full bg-[#1c1c1c] text-white rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-purple-600 transition-all"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {validationErrors.email && (
                  <p className="text-red-600 text-sm m-1">
                    {validationErrors.email}
                  </p>
                )}
              </div>
            </div>
           <div className="flex items-start gap-2 bg-rose-50 p-3 rounded-md border border-rose-200 mt-2 mb-4">
              <span className="text-rose-600 font-bold text-lg">*</span>
              <div>
                <span className="font-semibold text-rose-700">Important Note:</span>
                <p className="text-rose-600 text-sm mt-1">
                  Please ensure your full name matches exactly with your identity document and provide a unique email address.
                </p>
              </div>
            </div>
            <div className="w-full fixed bottom-0 left-0 px-4 md:px-[20px] py-4 md:py-[22px] bg-black">
              <Button onClick={handleSubmit} loading={loading}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default RetryPayment;
