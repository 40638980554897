import React, { Fragment, useState, useEffect } from "react";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import { getProviders, createPayment, getCurrencies } from "../../network/api";
import { toast, ToastContainer } from "react-toastify";

const CreatePaymentForm = ({ apiKey }) => {
  const [amount, setAmount] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencies, setCurrencies] = useState({});
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showProviderDropdown, setShowProviderDropdown] = useState(false);
  const navigate = useNavigate();
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedToken, setSelectedToken] = useState("");
  const [showTokenDropdown, setShowTokenDropdown] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currencySearch, setCurrencySearch] = useState("");

  const supportedTokens = {
    ethereum: {
      name: "Ethereum",
      symbol: "ETH",
      tokens: ["ETH", "USDT", "USDC"],
    },
    polygon: {
      name: "Polygon",
      symbol: "POL",
      tokens: ["POL", "USDT", "USDC"],
    },
    tron: {
      name: "Tron",
      symbol: "TRON",
      tokens: ["TRON", "USDT", "USDC"],
    },
    avalanche: {
      name: "Avalanche",
      symbol: "AVAX",
      tokens: ["AVAX", "USDT", "USDC"],
    },
    arbitrum: {
      name: "Arbitrum",
      symbol: "ARB",
      tokens: ["ARB", "USDT", "USDC"],
    },
    "binance-smart-chain": {
      name: "Binance Smart Chain",
      symbol: "BSC",
      tokens: ["BSC", "USDT", "USDC"],
    },
  };

  const fetchCurrencies = async () => {
    const response = await getCurrencies(apiKey);
    setCurrencies(response || {});
  };

  const fetchProviders = async () => {
    const response = await getProviders(apiKey);
    setProviders(response || []);
  };

  useEffect(() => {
    fetchCurrencies();
    fetchProviders();
  }, [apiKey]);

  const handleCreatePayment = async () => {
    if (!selectedProvider) {
      toast.error("Please select a payment provider");
      return;
    }

    if (!amount) {
      toast.error("Please enter an amount");
      return;
    }

    if (selectedProvider?.title === "CRYPTO" && !selectedToken) {
      toast.error("Please select a token");
      return;
    }

    if ((selectedProvider?.name?.toLowerCase() === "xenith" || selectedProvider?.name?.toLowerCase() === "xenon") && !customerName) {
      toast.error("Please enter your full name");
      return;
    }

    if ((selectedProvider?.name?.toLowerCase() === "xenith" || selectedProvider?.name?.toLowerCase() === "xenon") && !customerEmail) {
      toast.error("Please enter your email address");
      return;
    }

    if (selectedCurrency === "GBP" || selectedCurrency === "EUR") {
      if (selectedProvider?.name?.toLowerCase() === "xenith" && amount > 50000) {
        toast.error("Provider is not available for amounts greater than 50000.");
        return;
      }

      if (selectedProvider?.name?.toLowerCase() === "andromeda" && amount <= 50000) {
        toast.error("Provider is not available for amounts less than 50000."); 
        return;
      }
    }

    let payload;
    if (selectedProvider?.title === "CRYPTO") {
      const [tokenName, blockchainSymbol] = selectedToken.split("-");
      const blockchain = Object.entries(supportedTokens).find(
        ([_, value]) => value.symbol === blockchainSymbol
      );

      payload = {
        amount: amount,
        currency: selectedCurrency,
        currencyType: selectedProvider.name?.toLowerCase(),
        successCallback: "/payment/success",
        failureCallback: "/payment/failure",
        customerEmail: "dev@test.com",
        isKycOptional: true,
        crypto: {
          tokenName: tokenName,
          blockchainSymbol: blockchainSymbol,
          blockchainName: blockchain ? blockchain[0] : "ethereum",
        },
      };
    } else {
      payload = {
        amount: amount,
        currency: selectedCurrency,
        currencyType: selectedProvider.name?.toLowerCase(),
        successCallback: "/payment/success",
        failureCallback: "/payment/failure",
        isKycOptional: selectedProvider.name?.toLowerCase() === "xenith" || selectedProvider.name?.toLowerCase() === "xenon",
        ...((selectedProvider.name?.toLowerCase() === "xenith" || selectedProvider.name?.toLowerCase() === "xenon") && {
          customerEmail: customerEmail,
          customerName: customerName,
        }),
      };
    }

    setIsLoading(true);
    try {
      const response = await createPayment(apiKey, payload);
      if (response.paymentLink) {
        const url = new URL(response.paymentLink);
        const key = url.searchParams.get("key");
        const paymentId = url.searchParams.get("paymentId");
        navigate(`/?key=${key}&paymentId=${paymentId}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCurrencySelect = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
    setShowCurrencyDropdown(false);

    // Reset selected provider if it doesn't support the new currency
    if (selectedProvider && selectedProvider.title !== "CRYPTO") {
      if (!selectedProvider.currency?.supports?.includes(selectedCurrency)) {
        setSelectedProvider(null);
      }
    }
  };

  const filteredCurrencies = Object.values(currencies).filter(curr => 
    curr.code.toLowerCase().includes(currencySearch.toLowerCase())
  );

  // Add a function to filter providers based on selected currency
  const getFilteredProviders = () => {
    if (!selectedCurrency) return [];

    return providers.filter((provider) => {
      // For crypto provider, show it always as it handles all currencies
      if (provider.title === "CRYPTO") return true;

      // Apply amount restrictions only for GBP currency
      if (selectedCurrency === "GBP" || selectedCurrency === "EUR") {
        // Exclude Xenith provider if amount > 50000
        if (provider.name?.toLowerCase() === "xenith" && amount > 50000) {
          return false;
        }

        // Exclude Andromeda provider if amount <= 50000
        if (provider.name?.toLowerCase() === "andromeda" && amount <= 50000) {
          return false;
        }
      }

      // Check if provider has currency support
      if (provider.currency && provider.currency.supports) {
        return provider.currency.supports.includes(selectedCurrency);
      }

      return false;
    });
  };

  const handleCurrencyDropdownToggle = () => {
    setShowCurrencyDropdown(!showCurrencyDropdown);
    setShowProviderDropdown(false);
    setShowTokenDropdown(false);
  };

  const handleProviderDropdownToggle = () => {
    setShowProviderDropdown(!showProviderDropdown);
    setShowCurrencyDropdown(false);
    setShowTokenDropdown(false);
  };

  const handleTokenDropdownToggle = () => {
    setShowTokenDropdown(!showTokenDropdown);
    setShowCurrencyDropdown(false);
    setShowProviderDropdown(false);
  };

  return (
    <Fragment>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[90px] md:pb-[75px] signin-page-extra-pb">
        <a href="#" className="logo-link">
          <img
            src="/banksy-black-logo.svg"
            alt="logo"
            className="w-full logo-transparent-white"
          />
        </a>
        <p className="bnksy-page-title mt-8 mb-[42px]">Create Payment</p>

        <div className="w-full max-w-3xl mx-auto px-4 md:px-4">
          <div className="w-full flex gap-4 mb-8">
            <div className="flex-grow">
              <label
                htmlFor="amount-input"
                className="block text-sm text-gray-400 mb-2"
              >
                Amount
              </label>
              <input
                id="amount-input"
                type="number"
                className="w-full bg-[#1c1c1c] text-white rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-purple-600 transition-all"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </div>

            <div className="w-[100px] relative">
              <label
                htmlFor="currency-input"
                className="block text-sm text-gray-400 mb-2"
              >
                Currency
              </label>
              <div
                className="bg-[#1c1c1c] text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-purple-600 transition-all cursor-pointer flex justify-between items-center"
                onClick={handleCurrencyDropdownToggle}
              >
                <span
                  className={`overflow-hidden text-ellipsis whitespace-nowrap ${
                    selectedCurrency ? "text-white" : "text-gray-400"
                  }`}
                >
                  {selectedCurrency || "Select Currency"}
                </span>
                <svg
                  className={`w-4 h-4 text-gray-400 transform transition-transform duration-200 ${
                    showCurrencyDropdown ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9l6 6 6-6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </div>

              {showCurrencyDropdown && (
                <div className="absolute z-50 w-full mt-2 bg-[#1c1c1c] border rounded-xl shadow-lg">
                  <div className="p-2 border-b border-gray-700">
                    <input
                      type="text"
                      className="w-full bg-[#2a2a2a] text-white rounded-lg px-3 py-2 outline-none focus:ring-1 focus:ring-purple-600"
                      placeholder="Search currency..."
                      value={currencySearch}
                      onChange={(e) => setCurrencySearch(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  <div className="max-h-[200px] overflow-y-auto">
                    {filteredCurrencies.length > 0 ? (
                      filteredCurrencies.map((curr) => (
                        <div
                          key={curr.code}
                          className="px-4 py-3 hover:bg-purple-600 cursor-pointer transition-all flex items-center justify-between"
                          onClick={() => {
                            handleCurrencySelect(curr.code);
                            setCurrencySearch("");
                          }}
                        >
                          <span className="text-white">{curr.code}</span>
                          {selectedCurrency === curr.code && (
                            <svg
                              className="w-5 h-5 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-3 text-gray-400 text-center">
                        No currencies found
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {selectedCurrency ? (
            <div className="mb-8">
              <label className="block text-sm text-gray-400 mb-4">
                Select Payment Provider
              </label>

              <div className="relative">
                <div
                  className="flex items-center justify-between p-4 border rounded-xl cursor-pointer transition-all duration-200 ease-in-out border-gray-700 hover:border-purple-500 hover:bg-[#2a2a2a] group"
                  onClick={handleProviderDropdownToggle}
                >
                  {selectedProvider ? (
                    <div className="flex items-center">
                      <img
                        src={selectedProvider.image}
                        alt={selectedProvider.name}
                        className="w-8 h-8 mr-3 object-contain transition-transform duration-200 group-hover:scale-105"
                      />
                      <h2 className="text-sm font-semibold text-white truncate">
                        {selectedProvider.title}
                      </h2>
                    </div>
                  ) : (
                    <span className="text-gray-400">Select a provider</span>
                  )}
                  <svg
                    className={`w-4 h-4 text-gray-400 transform transition-transform duration-300 ease-in-out ${
                      showProviderDropdown ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 9l6 6 6-6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </div>

                <div
                  className={`absolute z-50 w-full mt-2 bg-[#1c1c1c] border border-gray-700 rounded-xl shadow-lg max-h-[200px] overflow-y-auto transition-all duration-300 ease-in-out origin-top ${
                    showProviderDropdown
                      ? "opacity-100 transform scale-100"
                      : "opacity-0 transform scale-95 pointer-events-none"
                  }`}
                >
                  {getFilteredProviders().map((provider, index) => (
                    <div
                      key={index}
                      className="flex items-center p-4 hover:bg-purple-600 cursor-pointer transition-all duration-200 ease-in-out hover:pl-6 first:rounded-t-xl last:rounded-b-xl group"
                      onClick={() => {
                        setSelectedProvider(provider);
                        setShowProviderDropdown(false);
                      }}
                    >
                      <img
                        src={provider.image}
                        alt={provider.name}
                        className="w-8 h-8 mr-3 object-contain transition-transform duration-200 group-hover:scale-110"
                      />
                      <h2 className="text-sm font-semibold text-white truncate group-hover:text-white">
                        {provider.title}
                      </h2>
                    </div>
                  ))}
                  {getFilteredProviders().length === 0 && (
                    <div className="p-4 text-gray-400 text-center">
                      No providers available for selected currency
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {selectedProvider?.title === "CRYPTO" && (
            <div className="mb-8">
              <label className="block text-sm text-gray-400 mb-4">
                Select Token
              </label>
              <div className="relative">
                <div
                  className="flex items-center justify-between p-4 border rounded-xl cursor-pointer transition-all duration-200 ease-in-out border-gray-700 hover:border-purple-500 hover:bg-[#2a2a2a] group"
                  onClick={handleTokenDropdownToggle}
                >
                  {selectedToken ? (
                    <div className="flex items-center">
                      <span className="text-white">{selectedToken}</span>
                    </div>
                  ) : (
                    <span className="text-gray-400">Select a token</span>
                  )}
                  <svg
                    className={`w-4 h-4 text-gray-400 transform transition-transform duration-300 ease-in-out ${
                      showTokenDropdown ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 9l6 6 6-6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </div>

                <div
                  className={`absolute z-50 w-full mt-2 bg-[#1c1c1c] border border-gray-700 rounded-xl shadow-lg max-h-[200px] overflow-y-auto transition-all duration-300 ease-in-out origin-top ${
                    showTokenDropdown
                      ? "opacity-100 transform scale-100"
                      : "opacity-0 transform scale-95 pointer-events-none"
                  }`}
                >
                  {Object.entries(supportedTokens).map(([blockchain, data]) =>
                    data.tokens.map((token) => (
                      <div
                        key={`${token}-${data.symbol}`}
                        className="flex items-center p-4 hover:bg-purple-600 cursor-pointer transition-all duration-200 ease-in-out hover:pl-6 first:rounded-t-xl last:rounded-b-xl group"
                        onClick={() => {
                          setSelectedToken(`${token}-${data.symbol}`);
                          setShowTokenDropdown(false);
                        }}
                      >
                        <div className="flex flex-col">
                          <span className="text-sm font-semibold text-white">
                            {token}
                          </span>
                          <span className="text-xs text-gray-400">
                            {data.name}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}

          {(selectedProvider?.name?.toLowerCase() === "xenith" || selectedProvider?.name?.toLowerCase() === "xenon") && (
            <div className="w-full mb-8">
              <div className="mb-4">
                <label className="flex items-center text-sm text-gray-400 mb-2">
                  Customer Name
                  <div className="relative ml-2 group">
                    <svg
                      className="w-4 h-4 text-gray-400 cursor-help"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 w-[200px] md:w-auto z-50">
                      <div className="relative">
                        Enter your full name exactly as it is on your identity document.
                        <div className="absolute w-2 h-2 bg-gray-900 transform rotate-45 left-1/2 -translate-x-1/2 bottom-[-4px]"></div>
                      </div>
                    </div>
                  </div>
                </label>
                <input
                  type="text"
                  className="w-full h-12 bg-[#1c1c1c] text-white rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-purple-600 transition-all text-base md:text-sm uppercase"
                  placeholder="Enter your full name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value.toUpperCase())}
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-2">
                  Customer Email
                </label>
                <input
                  type="email"
                  className="w-full h-12 bg-[#1c1c1c] text-white rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-purple-600 transition-all text-base md:text-sm"
                  placeholder="Enter your email address"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  required
                />
              </div>
            </div>
          )}

          <div className="w-full fixed bottom-0 left-0 px-4 md:px-[20px] py-4 md:py-[22px] bg-black">
            <Button onClick={handleCreatePayment} loading={isLoading}>
              Create Payment
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default CreatePaymentForm;
