import React, { useState, useEffect } from "react";
import RootLayout from "../../../component/layout";
import Awaiting from "../../../component/common/Awaiting";
import { useSelector } from "react-redux";
import { getPayment } from "../../../network/api";

const AndromedaPaymentStatus = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);

  const [status, setStatus] = useState("awaiting");

  const checkStatus = async () => {
    const response = await getPayment(paymentObj?.keyUsed, paymentObj?._id);
    setStatus(response.status);
    if (response.status == "success") {
      window.location.assign(
        `${paymentObj?.successCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`
      );
    } else if (response.status == "failed") {
      window.location.assign(
        `${paymentObj?.failureCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`
      );
    } else {
    }
  };

  useEffect(() => {
    checkStatus();
    let intervalId;
    if (status === "pending" || status === "awaiting") {
      intervalId = setInterval(checkStatus, 5000); // Check every 10 seconds
    }
    return () => clearInterval(intervalId);
  }, [status]);

  return (
    <RootLayout>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px]">
        <Awaiting
          imageSource="/Please-Wait.png"
          title="Payment in progress"
          description={
            <>
              Your payment is under process. It will be verified soon. It can
              take up to <strong>20 minutes</strong>. You can also go back to
              the site and your points will be automatically added.
            </>
          }
          backText="Back To Site"
          onBackClick={() =>
            (window.location.href = `${paymentObj?.successCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`)
          }
        />
      </div>
    </RootLayout>
  );
};

export default AndromedaPaymentStatus;
