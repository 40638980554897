import React, { useEffect } from "react";
import RootLayout from "../../component/layout";
import { useNavigate } from "react-router-dom";
import PaymentFailure from "../../component/payment/PaymentFailure";

const FailurePayment = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get("paymentId");
  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentId) {
      navigate("/error/config");
    }
  }, []);

  return (
    <RootLayout>
      <PaymentFailure
        imageSource="/failure.png"
        title="Payment Failed"
        description="We couldn't process your payment. Please try again."
        backText="Back to Home"
      />
    </RootLayout>
  );
};

export default FailurePayment;
