import React from 'react';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import Button from '../common/Button';

const PaymentSuccess = ({
  imageSource = "/success.png",
  title = "Payment Successful!",
  description = "Your transaction has been completed successfully",
  buttonText = "Back to Home",
  onButtonClick,
  backText,
  onBackClick
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      navigate('/');
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Title level={1} className="text-center !mb-0 common-lbl text-lbl-para fw-bold font-large error-big-title">
        {title}
      </Title>
      <div className="flex justify-center my-[50px]">
        <img src={imageSource} alt="success" className="w-[300px]" />
      </div>
      <Paragraph className="text-center common-lbl !mb-0">
        {description}
      </Paragraph>

      {(buttonText || backText) && (
        <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px] flex flex-col gap-3">
          {buttonText && (
            <Button onClick={handleButtonClick}>
              {buttonText}
            </Button>
          )}
          {backText && onBackClick && (
            <Button 
              type="default"
              onClick={onBackClick}
            >
              {backText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess; 