import { apiCall } from "../api";

export const getAndromedaConfig = async (apiKey, currency) => {
    try {
      const data = await apiCall(`/sdk/common/getOnramp?currency=${currency}`, "GET", null, apiKey);
  
      return {
        code: 1,
        data: data.config,
      };
    } catch (error) {
      return {
        code: 0,
        data: error.message || "Network error occurred",
      };
    }
  };