import React from 'react';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import Button from '../common/Button';

const PaymentFailure = ({
  imageSource = "/failure.png",
  title = "Payment Failed",
  description = "We couldn't process your payment. Please try again.",
  backText = "Back to Home",
  onBackClick
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate('/');
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Title level={1} className="text-center !mb-0 common-lbl text-lbl-para fw-bold font-large error-big-title">
        {title}
      </Title>
      <div className="flex justify-center my-[50px]">
        <img src={imageSource} alt="failed" className="w-[300px]" />
      </div>
      <Paragraph className="text-center common-lbl !mb-0">
        {description}
      </Paragraph>

      {(backText) && (
        <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px] flex flex-col gap-3">
          
          {backText && (
            <Button 
              type="default"
              onClick={handleBackClick}
            >
              {backText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentFailure; 