import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import RootLayout from "../../../component/layout";
import Awaiting from "../../../component/common/Awaiting";
import { getPayment } from "../../../network/api";

const DeerlickSuccess = () => {
  const location = useLocation();
  let { apikey } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get('paymentID');


  const [status, setStatus] = useState("awaiting");

  const checkStatus = async () => {
    const response = await getPayment(apikey, paymentId);
    setStatus(response.status);
    if (response.status === "success") {
      window.location.assign(response.successCallback + `?keyId=${apikey}&paymentId=${paymentId}`);
    } else if (response.status === "failed") {
      window.location.assign(response.failureCallback + `?keyId=${apikey}&paymentId=${paymentId}`);
    }
  };

  useEffect(() => {
    checkStatus();
    let intervalId;
    if (status === "awaiting" || status === "pending") {
      intervalId = setInterval(checkStatus, 5000); // Check every 10 seconds
    }
    return () => clearInterval(intervalId);
  }, [status]);

  return (
    <RootLayout>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px]">
        <Awaiting
          imageSource="/Please-Wait.png"
          title="Payment in progress"
          description="Your payment is under process. It will be verified soon. It can take up to 5 minutes."
          buttonText="Refresh Status"
          onButtonClick={checkStatus}
        />
      </div>
    </RootLayout>
  );
};


export default DeerlickSuccess;
