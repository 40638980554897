import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import Awaiting from "../../../component/common/Awaiting";
import { fetchXenithKycStatus } from "../../../redux/actions/xenithActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import RetryPayment from "../../../component/kyc/RetryPayment";

const XenithKycAwaiting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { keyUsed, id } = location.state || {};

  const apiKey = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );
  const paymentId = useSelector((state) => state?.reducer?.checkoutConfig?._id);
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [resstatus, setresstatus] = useState(true);
  const [rejectlabel, setRejectlabel] = useState("bad");

  // Function to check KYC status
  const checkStatus = async () => {
    try {
      const response = await dispatch(fetchXenithKycStatus(apiKey, paymentId));
      if (response?.data?.status === "FULL_USER") {
        navigate("/xenith");
      } else if (
        response?.data?.status == "SOFT_KYC_FAILED" ||
        response?.data?.status == "HARD_KYC_FAILED"
      ) {
        setRejectlabel(response?.data?.kycRejectLabel);
        setresstatus(false);
      } else {
        setStatus("awaiting");
      }
    } catch (error) {
      console.error("Error fetching KYC status:", error);
    }
  };

  // Submit KYC on component mount
  useEffect(() => {
    const initiateKyc = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setStatus("awaiting");
      } catch (error) {
        console.error("Error initiating KYC:", error);
      }
    };
    initiateKyc();
  }, [dispatch, keyUsed, id]);

  // Polling for KYC status
  useEffect(() => {
    let intervalId;
    if (status === "awaiting" && resstatus) {
      checkStatus();
      intervalId = setInterval(checkStatus, 2000); // Check every 2 seconds
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, resstatus]);

  return (
    <RootLayout>
      {resstatus ? (
        <Awaiting
          buttonText={"Go back"}
          title="Please wait..."
          description="Your KYC is being processed. This typically takes about 10-40 seconds, but may take up to 10 minutes if manual review is needed. Our verification success rate is 96-97%."
          imageSource="/Please-Wait.png"
        />
      ) : (
        <RetryPayment
          keyUsed={apiKey}
          paymentId={paymentId}
          err={rejectlabel}
        />
      )}

      <ToastContainer position="bottom-right" />
    </RootLayout>
  );
};

export default XenithKycAwaiting;
