import React, { useState, useEffect, useRef } from "react";
import QRCode from "qrcode";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../common/Button";
import accounting from "accounting";
import { paymentConfirmation } from "../../redux/actions/pinwheelActions";
import { getAndromedaConfig } from "../../redux/actions/andromedaActions";
import { uploadImageToImageKit } from "../../redux/actions/imagekitActions";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ReceiveAndromeda = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [seconds, setSeconds] = useState(600);
  const navigate = useNavigate();
  const [uploadReceiptLoader, setUploadReceiptLoader] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState("");
  const fileInputRef = useRef(null);
  const [receiptName, setReceiptName] = useState("");

  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const fetchAndromedaConfig = async () => {
    const response = await getAndromedaConfig(
      paymentObj?.keyUsed,
      paymentObj?.currency
    );
    setPaymentInfo(response?.data);
  };

  const handleConfirmPayment = async () => {
    const response = await paymentConfirmation(
      paymentObj?._id,
      paymentObj?.keyUsed,
      null,
      receiptUrl
    );

    if (response.code === 1) {
      navigate("/awaiting/andromeda");
    } else {
      toast.error(response.data);
    }
  };

  const handleFileChange = async (e) => {
    setUploadReceiptLoader(true);
    const file = e.target.files[0];
    setReceiptName(file.name);
    const response = await uploadImageToImageKit(file, paymentObj?.keyUsed);
    setReceiptUrl(response.data.url);
    setUploadReceiptLoader(false);
    setShowConfirmButton(true);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (paymentObj) {
      fetchAndromedaConfig();
    }
  }, [paymentObj]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: paymentObj });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="mb-3 w-full">
        <div
          className={
            seconds > 10
              ? "timer bg-orange header-right-col"
              : "timer bg-red header-right-col"
          }
        >
          Expires In: {minutes.toString().padStart(2, "0")}:
          {remainingSeconds.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="main-top-section-box w-full flex flex-col items-center relative">
        <h2 className="text-xl font-bold text-center">
          Pay{" "}
          <span className="text-[--primary-color]">
            {paymentObj?.currency}{" "}
            {accounting.formatNumber(paymentObj?.amount, { precision: 2 })}
          </span>
        </h2>
        <p className="text-gray-600 text-center mb-4">
          Pay by Bank Transfer - Fast and Secure
        </p>

        {paymentInfo ? (
          <div className="w-full max-w-md p-4 bg-gray-50 rounded-lg">
            <div className="space-y-3">
              {paymentInfo?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-between items-center"
                >
                  <span className="text-gray-600">{item.text}</span>
                  <div className="flex items-center">
                    <span className="font-medium">{item.value}</span>
                    <CopyToClipboard 
                      text={item.value}
                      onCopy={() => toast.success("Copied to clipboard!")}
                    >
                      <button
                        className="ml-2 p-1 bg-gray-200 rounded"
                    >
                      <img
                        src="/copy-icon.svg"
                        alt="copy"
                        className="w-3 h-3"
                      />
                    </button>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Transaction Note</span>
                <div className="flex items-center">
                  <span className="font-medium">{paymentObj?.meta?.note}</span>
                  <CopyToClipboard 
                    text={paymentObj?.meta?.note}
                    onCopy={() => toast.success("Copied to clipboard!")}
                  >
                    <button
                      className="ml-2 p-1 bg-gray-200 rounded"
                    >
                      <img src="/copy-icon.svg" alt="copy" className="w-3 h-3" />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-md p-4 bg-gray-50 rounded-lg flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[--primary-color]"></div>
          </div>
        )}

        <div className="flex flex-col items-center w-full mt-4">
          <div className="text-center mb-4">
            <h3 className="text-gray-800 font-semibold mb-2">
              {receiptName ? "Payment Receipt" : "Upload Payment Receipt"}
            </h3>
            <p className="text-gray-600 text-sm">
              {receiptName
                ? "Your receipt has been uploaded successfully"
                : "Please upload a screenshot or photo of your payment confirmation"}
            </p>
          </div>

          <div className="w-full max-w-md p-4 bg-gray-50 rounded-lg">
            {uploadReceiptLoader ? (
              <div className="flex flex-col items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[--primary-color]"></div>
                <p className="text-sm text-gray-600 mt-2">
                  Uploading receipt...
                </p>
              </div>
            ) : receiptName ? (
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-between w-full p-3 border rounded">
                  <div className="flex items-center">
                    <img
                      src="/file-icon.svg"
                      alt="file"
                      className="w-6 h-6 mr-2"
                    />
                    <span className="text-gray-700">{receiptName}</span>
                  </div>
                  <button
                    onClick={handleUploadClick}
                    className="text-blue-600 hover:text-blue-800 text-sm"
                  >
                    Reupload
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer"
                onClick={handleUploadClick}
              >
                <div className="flex flex-col items-center">
                  <div className="text-center">
                    <img
                      src="/upload.svg"
                      alt="upload"
                      className="w-12 h-12 mx-auto mb-2"
                    />
                    <p className="text-gray-600">Click to upload</p>
                  </div>
                </div>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              accept="image/*"
            />
          </div>
          {showConfirmButton && (
            <Button
              onClick={handleConfirmPayment}
              className="mt-4 w-full max-w-md"
            >
              {" "}
              Confirm Payment
            </Button>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ReceiveAndromeda;
