import axios from 'axios';
import Cookies from 'js-cookie';

// Store for parent message token
let parentToken = null;

// Listen for token from parent
window.addEventListener('message', function(event) {
  if (event.data && event.data.type === 'BANKSY_TOKEN_RESPONSE') {
    parentToken = event.data.token;
  }
}, false);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Function to get token with multiple fallbacks
const getToken = async () => {
  // Try direct cookie first
  let token = Cookies.get('customer_access_token');
  
  // If no direct cookie and we're in an iframe, try parent token
  if (!token && window.parent !== window) {
    // Request token from parent
    window.parent.postMessage({ type: 'BANKSY_TOKEN_REQUEST' }, '*');
    console.log('Requesting token from parent');
    // Wait briefly for parent response
    await new Promise(resolve => setTimeout(resolve, 100));
    token = parentToken;
  }
  
  return token;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    console.log('Token status:', token ? 'Found' : 'Not found');
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Authorization header set');
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiCall = async (url, method, data, apiKey = null, baseURL) => {
  try {
    const config = {
      url: baseURL || url,
      method,
      data,
    };

    if (apiKey) {
      config.headers = {
        ...config.headers,
        'X-AUTH': apiKey,
      };
    }

    const response = await axiosInstance.request(config);
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error.response?.data || error;
  }
};