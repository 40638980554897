import * as ActionTypes from "../actionTypes"; // Import your action types
import { apiCall } from "../api";

export const fetchPinwheelKeyId = (apikey) => async (dispatch) => {
  try {
    const response = await apiCall(
      `/sdk/common/getKey?module=pinwheel`,
      "GET",
      null,apikey
    );

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};

export const paymentConfirmation = async (id, apiKey, utr,url) => {
  try {
    const data = {
      id: id,
      ...(utr && { utr: utr }),
      ...(url && { url: url }),
    };
    const response = await apiCall(
      `/sdk/payment/confirm`,
      "POST",
      data,
      apiKey
    );

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error || "Network error occurred",
    };
  }
};