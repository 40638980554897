import axios from "axios";

 
export const uploadImageToImageKit = async (file, apikey) => {
  try {
    // Create FormData
    const formData = new FormData();
    formData.append('file', file);

    // Use our backend as a proxy to ImageKit
    const response = await axios.post(
      `https://sandbox-api.banksy.id/sdk/common/imagekit`, 
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-AUTH': apikey,
        }
      }
    );

    return {
      code: 1,
      data: response.data,
    };
  } catch (error) {
    console.error('Upload error:', error);
    return {
      code: 0,
      data: error.message || "Upload failed",
    };
  }
};